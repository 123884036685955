import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Strong, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги - Реконструкція Vividroluxe
			</title>
			<meta name={"description"} content={"Переосмислення вашого простору, одна кімната за одною - наш спектр послуг"} />
			<meta property={"og:title"} content={"Наші послуги - Реконструкція Vividroluxe"} />
			<meta property={"og:description"} content={"Переосмислення вашого простору, одна кімната за одною - наш спектр послуг"} />
			<meta property={"og:image"} content={"https://vividroluxe.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vividroluxe.com/img/574457.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vividroluxe.com/img/574457.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vividroluxe.com/img/574457.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vividroluxe.com/img/574457.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vividroluxe.com/img/574457.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vividroluxe.com/img/574457.png"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://vividroluxe.com/img/4.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text as="h1" font="--headline1" margin="0 0 12px 0">
				Наші послуги
				</Text>
				<Text font="--base">
				Vividroluxe Renovations пропонує широкий спектр послуг, щоб перетворити вашу квартиру на простір, який ідеально відповідає вашому баченню та стилю життя. Від незначного оновлення до повного капітального ремонту - наша команда здатна впоратися з усіма аспектами ремонту квартири.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="60%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				<Strong>
				Комплексні послуги з ремонту
				</Strong>
				<br />
				Капітальний ремонт квартири: Повномасштабний ремонт, який переосмислює кожен аспект вашої квартири, перетворюючи існуючий простір на дім вашої мрії.
				<br />
				<br />
				Ремонти кухонь та ванних кімнат: Спеціалізується на реконструкції кухонь та ванних кімнат з використанням сучасних приладів, ефективного планування та стильного оздоблення.

				<br />
				<br />
				<Strong>
				Індивідуальні рішення
				</Strong>
				<br />
				Dynamic Range: From high-energy cardio sessions to relaxing yoga and Pilates.Столярні вироби на замовлення та вбудовані елементи: Індивідуальні столярні рішення, включаючи індивідуальні шафи, полиці та вбудовані сховища, призначені для максимізації простору та додавання індивідуального стилю.
				<br />
				<br />
				Консультації з дизайну інтер'єру: Експертні поради щодо дизайну, які допоможуть обрати кольорову гаму, матеріали та декор, що відповідають вашому особистому стилю та функціональним потребам.

				<br />
				<br />
				<Strong>
				Додаткові пропозиції
				</Strong>
				<br />
				Модернізація освітлення та електрики: Модернізація систем освітлення для покращення атмосфери та енергоефективності, включаючи інтеграцію з "розумним будинком".
				<br />
				<br />
				Екологічний ремонт: Зосередьтеся на сталому розвитку, пропонуючи екологічно чисті матеріали та енергоефективні рішення.

			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://vividroluxe.com/img/5.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://vividroluxe.com/img/6.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Зв'яжіться з Vividroluxe
				<br />
				<br />

Щоб обговорити ваші потреби в ремонті або дізнатися більше про наші послуги:<br />
Телефон: +38(093)750 84 96<br />
Електронна пошта: Вулиця Житомирська 31, Звягель, 11702

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});